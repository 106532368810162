import React, { useState, useEffect } from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"
import LostImage from "../images/product/9.svg"
import {
  Center,
  Box,
  Heading,
  Container,
  HStack,
  VStack,
  Text,
  Image,
  Progress,
  Wrap,
  useMediaQuery,
} from "@chakra-ui/react"
import { useHistory, Redirect } from "react-router-dom"
import { navigate } from "gatsby"

function NotFoundPage() {
  const [percentComplete, setPercentComplete] = useState(0)
  const [seconds, setSeconds] = useState(0)
  const history = useHistory()

  function countdown() {
    setPercentComplete(percentComplete + 0.1)
  }

  useEffect(() => {
    if (percentComplete < 100) {
      const interval = setInterval(countdown, 10)
      return () => {
        clearInterval(interval)
      }
    } else {
      navigate("/")
    }
  }, [percentComplete])

  const [isLargerThan1000] = useMediaQuery("(min-width: 1000px)")

  return (
    <Layout>
      <SEO title="404: Not found" />
      <Navigation colorOverride="black" />
      <Container justifyContent="center" centerContent h="100vh">
        <HStack spacing={6}>
          <VStack justifyContent="center" boxSize="500px">
            <Heading size="3xl" color="black">
              404 Page Not Found
            </Heading>
            <Text>
              We're sorry for any inconvenience. If you're sure you're on the
              right page, please try again later. No fear! You'll be routed back
              to the home page in 10 seconds.
            </Text>
          </VStack>
          <Image bg="transparent" boxSize="700px" src={LostImage}></Image>
        </HStack>
      </Container>
      <Progress
        mt={-2}
        value={percentComplete}
        min={0}
        max={100}
        size="lg"
        colorScheme="blue"
      />
    </Layout>
  )
}

export default NotFoundPage
